@import "nlib/config";

.customDropArea {
  width: 100%;
  border-radius: $uiBorderRadius;
  text-align: center;
  .content {
    padding: $contentDefaultIndent * 2;
    svg {
      font-size: 1.5rem;
    }
    .title {
      color: $uiFontColor;
      font-size: 0.875rem;
    }
    .info {
      color: $uiMediumDarkColor;
      font-size: 0.75rem;
    }
  }
}
