@import "nlib/config";

.createFolderWindow {
  .content {
    position: relative;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin-top: $contentDefaultIndent * 2;
    button {
      padding-right: $contentDefaultIndent * 4;
      padding-left: $contentDefaultIndent * 4;
      + button {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
  .uniqueFolderNameError {
    min-width: 10em;
    color: $uiNegativeColor;
  }
}
