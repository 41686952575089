$contentMinWidth: 1010px;
$contentMaxWidth: 1920px;
$simplifiedLayoutMaxWidth: 1200px;
$appHeaderHeight: 60px;
$pageHeaderHeight: 62px;
$contentDefaultIndent: 8px;
$uiMobileFontSize: 0.875rem;
$uiDesktopFontSize: 0.8125rem;
$menuFullWidthMinViewport: 1600px;
$menuFullWidth: 210px;
$uiWhiteColor: #fff;
$uiMediumDarkColor: #8c96a0;
$uiDarkColor: #5a6169;
$uiBlackColor: #000;
$uiHighlightColor: #007bff;
$uiSpecialColor: #9845f7;
$uiInfoColor: #00b8d8;
$uiWarningColor: #ffb400;
$uiPositiveColor: #17c671;
$uiNegativeColor: #c4173c;
$uiAccentColor: #ff4169;
$uiNeutralColor: #becad6;
$uiPreloaderColor: #2940ea;
$uiBackgroundColor: #f5f6f8;
$uiBorderColor: #e5e5e5;
$uiHeaderColor: #f9f9f9;
$uiPlaceholderColor: darken($uiNeutralColor, 6%);
$uiHeaderGradient: linear-gradient(to bottom, $uiWhiteColor, rgba($uiBackgroundColor, 0.85));
$uiComponentDisabledOpacity: 0.5;
$uiFontFamily: "Roboto", sans-serif;
$uiBorderRadius: $contentDefaultIndent / 2;
