@import "nlib/config";

.dateRangePickerExtended {
  color: $uiPrimaryColor;
  font-size: 1rem;
  .toggleButton {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: inherit;
    svg {
      margin-right: $contentDefaultIndent;
      color: $uiNeutralColor;
      font-size: 1.125rem;
    }
    .label {
      padding-right: $contentDefaultIndent;
      color: $uiFontColor;
      font-weight: 500;
    }
    .date {
      margin-left: auto;
      font-weight: 500;
      .placeholder {
        color: $uiNeutralColor;
        font-weight: normal;
      }
    }
  }
  &.active {
    .toggleButton {
      svg {
        color: $uiPrimaryColor;
      }
    }
  }
}
.dateRangePickerDropDown {
  display: block !important;
  padding: 0;
  overflow: auto;
  .extension {
    .row {
      display: flex;
      padding: 0 $contentDefaultIndent;
      + .row {
        margin-top: $contentDefaultIndent;
      }
      .col {
        min-width: 50%;
        padding: 0 $contentDefaultIndent;
        .button {
          width: 100%;
          height: 2.25rem;
          margin: 0;
          overflow: hidden;
          border: 1px solid $uiBorderColor;
          color: $uiPrimaryColor;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .additional {
    display: flex;
    .button {
      flex: 1;
      width: 100%;
      + button {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
}

body[data-mobile] {
  .dateRangePickerDropDown {
    width: 20rem !important;
  }
}
