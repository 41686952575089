@import "nlib/config";
@import "animations";

.vaultPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: $mobileNavBarHeight;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $contentDefaultIndent 0 $contentDefaultIndent * 2;
  }
  .folders {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$contentDefaultIndent / 2;
    margin-left: -$contentDefaultIndent / 2;
  }
  .folder {
    display: flex;
    position: relative;
    align-items: center;
    max-width: 100%;
    margin: 0 $contentDefaultIndent / 2 $contentDefaultIndent;
    padding: $contentDefaultIndent * 2 $contentDefaultIndent * 1.5;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    box-shadow: 0 1px 4px rgba(70, 70, 70, 0.1);
    white-space: nowrap;
    svg {
      font-size: 1.25rem;
    }
    .folderName {
      margin-right: 0.6em;
      margin-left: 0.3em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .folderFilesCount {
      margin-left: auto;
    }
  }
}
