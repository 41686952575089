@import "nlib/config";

.attachment {
  display: flex;
  align-items: center;
  margin-bottom: $contentDefaultIndent * 1.5;
  .number {
    min-width: 1.25rem;
    max-width: 1.25rem;
    margin-right: 0.3em;
    font-weight: 500;
    text-align: right;
  }
  .icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    margin-right: $contentDefaultIndent * 1.5;
    overflow: hidden;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-size: cover;
    cursor: pointer;
    &:hover {
      background-color: rgba($uiBorderColor, 0.1);
    }
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: $contentDefaultIndent * 1.5;
    overflow: hidden;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    cursor: pointer;
    .name {
      overflow: hidden;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: $uiBlackColor;
      }
    }
    .meta {
      color: $uiMediumDarkColor;
    }
  }
  .delete,
  .loading {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: $contentDefaultIndent / 2;
    svg {
      font-size: 1.125rem;
    }
  }
  .delete {
    color: $uiNegativeColor;
    cursor: pointer;
    &:hover {
      color: $uiFontColor;
    }
  }
}
