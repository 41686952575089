@import "nlib/config";

.listItemDetails {
  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    svg {
      font-size: 1.25rem;
    }
    > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .negative {
      color: $uiNegativeColor;
    }
    .warning {
      color: $uiWarningColor;
    }
    .button {
      margin-right: $contentDefaultIndent;
    }
  }
  .row {
    display: flex;
    width: 100%;
    .col {
      flex: 1;
      max-width: 100%;
      + .col {
        margin-left: $contentDefaultIndent;
      }
    }
  }
  .fileInfo {
    margin-bottom: $contentDefaultIndent * 2;
    text-align: center;
    cursor: pointer;
    .icon svg {
      font-size: 2.5rem;
    }
    .name {
      text-decoration: underline;
      word-break: break-word;
    }
    &:hover {
      .icon {
        opacity: 0.75;
      }
      .name {
        text-decoration: none;
      }
    }
  }
  .colTablet {
    margin-bottom: $contentDefaultIndent * 1.5;
    .colTablet {
      margin-bottom: 0;
    }
  }
  .activity {
    margin-bottom: $contentDefaultIndent * 2;
  }
  .preloader {
    z-index: 1;
  }
}

@media (min-width: $viewportSizeMedium) {
  .listItemDetails {
    .description {
      textarea {
        min-height: 92px;
      }
    }
    .rowTablet {
      display: flex;
      .colTablet {
        flex: 1;
        width: 1px;
        + .colTablet {
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
  }
}
