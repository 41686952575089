@import "nlib/config";

.listItem {
  width: 100%;
  margin: 0 auto $contentDefaultIndent;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow: 0 1px 4px rgba(70, 70, 70, 0.1);
  .mutedText {
    color: $uiNeutralColor;
  }
  .header {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent $contentDefaultIndent * 1.5 0;
    .info {
      flex: auto;
      margin-right: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .icon {
        margin-right: 0.3em;
        font-size: 1.25rem;
      }
    }
    .comments {
      display: flex;
      align-items: center;
      margin-left: $contentDefaultIndent;
      font-size: 0.6875rem;
      line-height: 1;
      &.unread {
        color: $uiPrimaryColor;
      }
      svg {
        font-size: 1rem;
      }
    }
  }
  .content {
    display: flex;
    padding: $contentDefaultIndent $contentDefaultIndent * 1.5;
    white-space: nowrap;
    .date {
      margin-right: $contentDefaultIndent * 2;
    }
    .tags {
      flex: auto;
      margin-right: $contentDefaultIndent * 2;
      overflow: hidden;
      color: $uiMediumDarkColor;
      text-overflow: ellipsis;
    }
    .type {
      margin-left: auto;
    }
  }
}
