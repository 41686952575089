@import "nlib/config";
@import "animations";

.pageActionButtons {
  display: flex;
  position: sticky;
  bottom: 93px;
  width: 100%;
  max-width: 32rem;
  margin-right: auto;
  margin-left: auto;
  background-color: $uiBackgroundColor;
  box-shadow: 0 0 16px 8px $uiWhiteColor;
  button {
    flex: 1;
    + button {
      margin-left: $contentDefaultIndent;
    }
  }
  [data-loading] svg {
    animation: rotate 2s linear infinite;
  }
}
